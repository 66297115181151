import React, { useState } from 'react';
import './ContactForm.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch('/api/contact', {  // Assuming you're using the proxy setup
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));
    /*try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const responseData = await response.json();
      console.log('Server response:', responseData);

      if (response.ok) {
        alert('Message sent successfully!');
        // Optionally reset the form or handle the response further
        setFormData({ name: '', email: '', message: '' });
      } else {
        alert('Failed to send message.');
      }
    } catch (error) {
      console.error('Error sending form data:', error);
      alert('Error sending message. Please try again later.');
    }*/
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <label>
          <span>Name:</span>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </label>
        <label>
          <span>Email:</span>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
        <label>
          <span>Message:</span>
          <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>
        </label>
        <button type="submit">Send</button>
      </form>
    </div>
  );
}

export default ContactForm;
