import React, { useRef, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import ContactForm from './ContactForm';

function App() {
  const backgroundImageStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/header.jpg)`,
  };

  const marqueeRef = useRef(null);
  const intervalRef = useRef(null);
  const scrollDirectionRef = useRef(1); // 1 for right (scrolling forward), -1 for left (scrolling back)
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollStart = useRef(0);

  // Function to start the auto-scrolling with bouncing effect
  const startAutoScroll = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      if (marqueeRef.current) {
        let newScrollLeft = marqueeRef.current.scrollLeft + scrollDirectionRef.current;
        
        // Check bounds and reverse direction if needed
        if (newScrollLeft >= marqueeRef.current.scrollWidth - marqueeRef.current.clientWidth) {
          scrollDirectionRef.current = -1; // Change direction to left
        } else if (newScrollLeft <= 0) {
          scrollDirectionRef.current = 1; // Change direction to right
        }
        
        marqueeRef.current.scrollLeft = newScrollLeft;
      }
    }, 20);
  };

  useEffect(() => {
    startAutoScroll();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleStart = (position) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    isDragging.current = true;
    startX.current = position;
    scrollStart.current = marqueeRef.current.scrollLeft;
  };

  const handleMove = (position) => {
    if (!isDragging.current) return;
    const walk = position - startX.current;
    marqueeRef.current.scrollLeft = scrollStart.current - walk;
  };

  const handleEnd = () => {
    isDragging.current = false;
    startAutoScroll();
  };

  // Mouse event handlers
  const handleMouseDown = (e) => {
    handleStart(e.pageX);
    e.preventDefault(); // Prevent default actions like text selection
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 1) { // Check if the primary button is pressed
      handleMove(e.pageX);
    }
  };

  const handleMouseUp = () => {
    handleEnd();
  };

  // Touch event handlers
  const handleTouchStart = (e) => {
    handleStart(e.touches[0].pageX);
    e.preventDefault(); // Prevent default page scrolling
  };

  const handleTouchMove = (e) => {
    handleMove(e.touches[0].pageX);
    e.preventDefault(); // Continue to prevent default page scrolling
  };

  const handleTouchEnd = () => {
    handleEnd();
  };


  const mapImageRef = useRef(null);
  const markerRef = useRef(null);
  useEffect(() => {
    const updateMarkerPosition = () => {
      const mapImg = mapImageRef.current;
      if (mapImg && markerRef.current) {
        const imgWidth = mapImg.offsetWidth;
        const imgHeight = mapImg.offsetHeight;

        // Adjust these based on where you need the marker to be
        const markerXPercentage = 22.5; // example: 50% of the width
        const markerYPercentage = 51.5; // example: 50% of the height

        markerRef.current.style.left = `${(imgWidth * markerXPercentage) / 100}px`;
        markerRef.current.style.top = `${(imgHeight * markerYPercentage) / 100}px`;
      }
    };

    window.addEventListener('resize', updateMarkerPosition);
    updateMarkerPosition(); // Initial position update

    return () => {
      window.removeEventListener('resize', updateMarkerPosition);
    };
  }, []);

  return (
    <div className="App">
      <div className="announcement">
        <div className="trapezoid">
          <p>We are currently under construction. Please check back soon for updates.</p>
        </div>
      </div>
      <header className="App-header" style={backgroundImageStyle}>
        <img src={logo} className="App-logo" alt="logo" />
        <p>Welcome to Camp Waluhi'Yi!</p>
        <div className="chevron">
          <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 2.20277L19.8891 0L11.0738 9.55609L2.07326 0.196619L0 2.47772L11.1476 14L22 2.20277Z" fill="#F6F1E4"></path>
          </svg>
        </div>
      </header>
      <div className="container">
        <section className="mission-section">
          <h1>Mission</h1>
          <p>Provide secure facilities for camping and outdoor nature experience for the youth and adults of the City of Duncan and Stephens County</p>
        </section>
        <section className="location-section">
          <div className="map-info">
            <h1>Location:</h1>
            <p>Located at 290498 E 1715 Rd, Marlow, OK 73055</p>
          </div>
          <div className="map">
            <a href="https://maps.app.goo.gl/cmg1ckMrJgwmARWR8" target="_blank" rel="noopener noreferrer">
              <img ref={mapImageRef} src={process.env.PUBLIC_URL + '/images/googlemap.png'} alt="location" className="map-image" />
              <div ref={markerRef} className="map-marker">
                <span className="marker-text">Camp Waluhi'Yi</span>
                <div className="marker-arrow"></div>
              </div>
            </a>
            <p>Click the map to route to us!</p>
          </div>
        </section>
        <div
          className="image-marquee-wrapper"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="image-marquee" ref={marqueeRef}>
            <img src={process.env.PUBLIC_URL + `/images/image1.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image2.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image3.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image4.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image5.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image6.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image7.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image8.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image9.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image10.jpg`} alt="Description" />
          </div>
        </div>
        <section className="amenities-section">
          <h1>Amenities</h1>
          <div className="amenities-boxes">
            <div className="amenities-box">
              <h1>Facilities</h1>
              <ul>
                <li>Campsites</li>
                <li>Cabins</li>
                <li>Shelters</li>
                <li>Restrooms</li>
              </ul>
            </div>
            <div className="amenities-box">
              <h1>Recreation</h1>
              <ul>
                <li>Swimming</li>
                <li>Gaga Ball</li>
                <li>Canoes</li>
                <li>Kayaks</li>
                <li>Fishing</li>
              </ul>
            </div>
            <div className="amenities-box">
              <h1>Comfort</h1>
              <ul>
                <li>Electricity</li>
                <li>Fire Rings</li>
                <li>BBQ Grills</li>
                <li>Water Spigots</li>
              </ul>
            </div>
            <div className="amenities-box">
              <h1>Scenery</h1>
              <ul>
                <li>Scenic Views</li>
                <li>Hiking Trails</li>
                <li>Picnic Areas</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="contact-section">
          <div className="contact-info">
            <h1>Contact Us</h1>
            <p>For more information, please contact us at:</p>
            <p>Phone: (580)-255-5511</p>
            <p>Email: campwaluhiyi@gmail.com</p>
          </div>
          <ContactForm/>
        </section>
        <section id="our-team">
          <h2>Our Team</h2>
          <div class="team-grid">
            <div class="team-member">
              <h3>President</h3>
              <p>Member Name</p>
              <img src={process.env.PUBLIC_URL + `/images/member.svg`} alt="Member 1"></img>
            </div>
            <div class="team-member">
              <h3>Vice President</h3>
              <p>Member Name</p>
              <img src={process.env.PUBLIC_URL + `/images/member.svg`} alt="Member 2"></img>
            </div>
            <div class="team-member">
              <h3>Treasurer</h3>
              <p>Member Name</p>
              <img src={process.env.PUBLIC_URL + `/images/member.svg`} alt="Member 3"></img>
            </div>
            <div class="team-member">
              <h3>Nominating Chairman</h3>
              <p>Member Name</p>
              <img src={process.env.PUBLIC_URL + `/images/member.svg`} alt="Member 4"></img>
            </div>
            <div class="team-member">
              <h3>Reservations Chairman</h3>
              <p>Member Name</p>
              <img src={process.env.PUBLIC_URL + `/images/member.svg`} alt="Member 5"></img>
            </div>
            <div class="team-member">
              <h3>Maintenance Chairman</h3>
              <p>Member Name</p>
              <img src={process.env.PUBLIC_URL + `/images/member.svg`} alt="Member 6"></img>
            </div>
            <div class="team-member">
              <h3>Development Chairman</h3>
              <p>Member Name</p>
              <img src={process.env.PUBLIC_URL + `/images/member.svg`} alt="Member 7"></img>
            </div>
            <div class="team-member">
              <h3>Board Member</h3>
              <p>Member Name</p>
              <img src={process.env.PUBLIC_URL + `/images/member.svg`} alt="Member 8"></img>
            </div>
            <div class="team-member">
              <h3>Board Member</h3>
              <p>Member Name</p>
              <img src={process.env.PUBLIC_URL + `/images/member.svg`} alt="Member 9"></img>
            </div>
          </div>
        </section>
        <section className="history-section">
          <div className="history-info">
            <h1>History</h1>
            <p>Founded in 1953, the camp began when the Duncan Girl Scout Council leased 65 acres from the City of Duncan.
              Around that time, the Quonset hut, then known as the “lodge”, was constructed, marking the initial development of the camp.
              In 1967, as the Girl Scouts became part of the Sooner Girl Scout Council, the camp was transferred to the Camp Waluhi'Yi Association, a non-profit corporation.</p>
              
            <p>Over the years, the camp expanded to include several key facilities: a garage, tool shed, a primitive cabin now called the “Pioneer Cabin”, an administrative/first aid building, and six cabins referred to as the “Girl Scout Cabins”.
              Historically, the camp also featured two camping areas with six tent platforms each, a canoeing beach, and an archery range.
              Historical records show that the camp was available for group use at a fee of $2.00 per night</p>
          </div>
        </section>
      </div>
      <footer id="main-footer">
        <div class="footer-content">
          <div class="footer-section contact">
            <h3>Contact Us</h3>
            <p>Phone: (580)-255-5511</p>
            <p>Email: campwaluhiyi@gmail.com</p>
            <p>Address: 290498 E 1715 Rd, Marlow, OK 73055</p>
          </div>
        </div>
        <div class="footer-bottom">&copy; 2024 Waluhi'yi</div>
      </footer>
    </div>
  );
}

export default App;
